
import ProjectService from '../../services/ProjectService';
import DocumentService from '../../services/DocumentService'
import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { useLocation } from 'react-router-dom';


const ViewProject = ({ location }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [fileName, setFileName] = useState(null);
  const projectId = new URLSearchParams(location.search).get('id');


  useEffect(() => {
    if (projectId) {
      getProjectData(projectId);
    }
  }, [projectId]);

  const getProjectData = (id) => {
    ProjectService.findOne({ id: id })
      .then((res) => {
        setProjectData(res);
        if (res && res.attachments) {
          getDocument(res.attachments);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getDocument = async (id) => {
    try {
      let $doc = await DocumentService.get({ id: parseInt(id) });
      setFileName($doc?.data?.data[0].documents.file_name);
      let $image = await DocumentService.imageProfile({ id: parseInt(id) });
      setImageSrc($image);
      console.log($image);
    } catch (error) {
      console.error(error);
    }
  };



  return (
    <>
     <div style={{ margin: '90px' }}>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <div style={{ display: 'flex' }}>
            <form style={{ width: '100%' }}>
              <div>
                <div className="text-center mb-4">
                  <h2>Project View</h2>
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <label>
                    <strong>Subject:</strong>
                  </label>
                  <p>{projectData && projectData.subject ? projectData.subject : ''}</p>
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <label>
                    <strong>Stage:</strong>
                  </label>
                  <p>{projectData && projectData.stage ? projectData.stage : ''}</p>
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <label>
                    <strong>Description:</strong>
                  </label>
                  <p dangerouslySetInnerHTML={{ __html: projectData && projectData.description ? projectData.description : '' }}></p>
                </div>
                <div style={{ marginBottom: '20px' }}>
                  {fileName && (
                    <div>
                      <label>
                        <strong>File Name:</strong>
                      </label>{' '}
                      {fileName}
                    </div>
                  )}
                  {imageSrc && (
                    <div>
                      <br />
                      <img style={{ width: '100%', maxWidth: '300px', marginTop: '10px' }} src={imageSrc} alt="Project" />
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </Card.Body>
      </Card>
    </div>

    </>
  );

}
export default ViewProject;
