import React from "react";
import styled from "styled-components";
import rhsimg from "../../assets/img/banner3.jpg";
import secondImage from '../../assets/img/banner2.jpg'
import thirdImage from '../../assets/img/banner5.jpg'
import Fade from 'react-reveal/Fade';
import { Carousel} from 'antd';
export default function Services() {
  return (
    <Wrapper id="services" className="whiteBg">
      <div style={{ padding: "60px 0" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <Fade left>
                <HeaderInfo>
                  <h1 className="font30 extraBold" style={{ padding: "35px 0" }}>SUBSTATION CONSTRUCTION & EQUIPMENT ERECTION</h1>
                  <p className="font20 lineHeight30">
                    We are Authorized to Construct Medium Voltage substation                         ( Primary (33/11) KV and Distribution Subsations (11/0415) KV)   We have constructed almost 500 Nos of Distribution Substations all over oman, (Types : Package / Pocket , Compound Wall Substations with sandwitched roofings, GRP & Cage type)   Out Expert team will guide you through Architectural Design work (ACAD) - Architectural Construction, Electrical switchgears and Equipment installation, Testing and commissioning of power equipments installed in power plant,  Maintenance - Reconstruction & Rehaliblation Works, Extension of substations, Replacement of primary and secondary devices inside power plants - Our team will provide you Extensive experience, resolute commitment to safety, professional competence with highest quality standards
                  </p>
                </HeaderInfo>
              </Fade>
            </div>
            <div className="col-lg-6">
              <Carousel autoplay>
                <div>
                  
                <img src={rhsimg} alt="logo" style={{width:'100%',height:500}}/>
                </div>
                <div>
                <img src={secondImage} alt="logo" style={{width:'100%',height:500}}/>
                </div>
                <div>
                <img src={thirdImage} alt="logo" style={{width:'100%',height:500}}/>
                </div>
              </Carousel>
              {/* <div>
              <img src={rhsimg} className="img-fluid rounded-circle" alt="Service"/>
           </div> */}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;