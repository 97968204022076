import React from "react";
import styled from "styled-components";

// Assets
import developerImg from "../../assets/img/banner4.jpg";
import { Fade } from "react-reveal";

export default function TestingandCommissioning() {
  return (
    <Wrapper id="developer">
      <div className="lightBg">
        <div className="container">       
          <div className="row flexCenter" style={{ paddingBottom: "100px" }}>
            <div className="col-xs-12 col-sm-12 col-md-8">
              <Fade left>
              <HeaderInfo>
                <h1 className="font40 extraBold" style={{ padding: "35px 0" }}>TESTING AND <span className="highlight-txt"> COMMISSIONING.</span></h1>
                  <p className="font18 lineHeight40">
                  AGAF have a specialized testing team with DCRP approved Testing Engineers and high precision advanced testing equipments,  Electrical testing is needed for electrical equipment functioning properly after installation and has become a key component for equipment safety to include performance, operations, and energy requirements for electrical facilities.  AGAF does the quality-oriented process for achieving, verifying, and documenting the performance of individual electrical equipment, controlling devices as well as systems that meet defined objectives and criteria. We ensure that the system is safe and meet the design requirements
                </p>
            </HeaderInfo>
            </Fade>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4">           
              <div style={{ width: "100%"}}>
                <div style={{ marginTop: "50px"}}>
                  <img src={developerImg} alt="office" className="img-fluid rounded"/>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;









