import MainService from './MainService'
const querystring = require('querystring')
var DocumentService = {
    get(value){
        return new Promise(function (resolve, reject) {
            let $data={};
            $data.url='getDocument';
            $data.method='get';
            $data.params = value;
             MainService.getResult($data)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error);
                })
        });
       
    },
    
    imageProfile: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "imageProfile"
            $options.method = "get"

            if ($data) {
                $options.url = "imageProfile?" + querystring.stringify($data)
                console.log($options.url)
            }
            MainService.getResult($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },

    upload($data) {
        return new Promise(function (resolve, reject) {
            var $options = {};
            $options.url = 'upload'
            $options.method = 'post'
            $options.data = $data
            MainService.getResult($options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error);
                })
        });
    },
    
  
    download: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            // $options.responseType = "blob"
            $options.url = "documentDownload"
            $options.method = "get"

            if ($data) {
                $options.url = "documentDownload?" + querystring.stringify($data)
                console.log($options.url)
            }
            MainService.getResult($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },

    getImage: function ($data) {
        return new Promise(function (resolve, reject) {
            var $options = {};
            // $options.responseType="blob"
            $options.url = "getImageFromDocument/?" + querystring.stringify($data)
            $options.method = "get"
            MainService.getResult($options)
                .then(response => {

                    resolve(response.data)
                }).catch(error => {
                    reject(error);
                })
        });
    },
    getDocument: function ($file) {
        // Pass documentId and documentType in request body to get the file from the server
        return new Promise(function (resolve, reject) {
            var $options = {};
            $options.url = 'documents/get'
            $options.method = "post"
            $options.data = $file
            console.log($options, "working111111")
            MainService.getResult($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })

        });
    },
};
export default DocumentService;