import React from "react";
import {useEffect,useState} from 'react';
import styled from "styled-components";
// Components
import p1 from "../../assets/img/Picture1.jpg";
import p2 from "../../assets/img/Picture2.jpg";
import Bounce from 'react-reveal/Bounce';
import ProjectService from '../../services/ProjectService';

export default function Projects() {
  useEffect(() => {
    getProjectDataWithUrl();
  },[]);
  const getProjectDataWithUrl = () => {
    ProjectService.findAllWithUrl()
      .then((res) => {
        setAllappsData(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const [AllappsData, setAllappsData] = useState([])
  // [
  //   { 
  //     id:1,
  //     name:'ELECTRICAL WORKS TENDER BASED ON UNIT RATE/SUWAIQ',
  //     description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.',
  //     url:'app.lyra.finance',
  //     iconImage:p1
  //   },
  //   {
  //     id:2,
  //     name:'ELECTRICAL WORKS TENDER BASED ON UNIT RATE/SUWAIQ',
  //     description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.',
  //     url:'app.lyra.finance',
  //     iconImage:p2
  //   },
  //   {
  //     id:3,
  //     name:'ELECTRICAL WORKS TENDER BASED ON UNIT RATE/SUWAIQ',
  //     description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.',
  //     url:'app.lyra.finance',
  //     iconImage:p1
  //   },
  //   {
  //     id:4,
  //     name:'ELECTRICAL WORKS TENDER BASED ON UNIT RATE/SUWAIQ',
  //     description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.',
  //     url:'app.lyra.finance',
  //     iconImage:p2
  //   },
  //   {
  //     id:5,
  //     name:'ELECTRICAL WORKS TENDER BASED ON UNIT RATE/SUWAIQ',
  //     description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.',
  //     url:'app.lyra.finance',
  //     iconImage:p1
  //   },
  //   {
  //     id:6,
  //     name:'ELECTRICAL WORKS TENDER BASED ON UNIT RATE/SUWAIQ',
  //     description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.',
  //     url:'app.lyra.finance',
  //     iconImage:p2
  //   },
  //   {
  //     id:7,
  //     name:'ELECTRICAL WORKS TENDER BASED ON UNIT RATE/SUWAIQ',
  //     description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.',
  //     url:'app.lyra.finance',
  //     iconImage:p1
  //   },
  //   {
  //     id:8,
  //     name:'ELECTRICAL WORKS TENDER BASED ON UNIT RATE/SUWAIQ',
  //     description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.',
  //     url:'app.lyra.finance',
  //     iconImage:p2
  //   },
  // ]
  // useEffect(() => {
  //   if (document.body.classList.contains('all-apps')) {
  //     console.log('body tag contains class');
  //   }
  //   else {
  //     document.body.classList.remove('all-apps');
  //   }
  // }, []);

  return (
    <Wrapper id="projects">
      <div className="">
      <div className="banner-sec-bg">
      <div className="container" style={{margin:"0px auto 0 auto"}}>
            <div className="row align-items-center">
            <div className="col-md-6">
              <HeaderInfo >
                <h1 className="font40 extraBold whiteColor">Our Projects</h1>
              </HeaderInfo>
              </div>
              {/* <div className="col-md-6 textRight">
                <img src={bannerImg} className="img-fluid allapps-banner"/>
              </div> */}
            </div> 
          </div>
          </div>
        <div className="container" style={{margin:"0px auto 0 auto"}}>
        
          <h2 className="font16 my-4">CURRENT PROJECTS</h2>
          <div className="row textCenter mb-5">
            
            {AllappsData.map((app, index) => (
            <div style={{padding:10}} className="col-xs-12 col-sm-4 col-md-4 col-lg-3" key={index}>
             <Bounce>
             
              <Wrapper className="radius8 shadow all-apps-card h-100">
                {/* <div className="flex flexNullCenter flex-end">
                  <div><img src={liveIcon} alt="office" className="img-fluid" /></div>
                  <div className="font16 primaryColor">Live Projects</div>
                </div> */}
                <div className="h-100">
                  <div className="project-header">
                  {app.url ? <img src={app.url} alt="" className="img-fluid appsImage" /> : <></>}
                  </div>
                  <div className="p-4">
                  <div className="font15 extraBold mb-0">Title</div>
                   <div className="font14 mb-0 bridge-title">{app.subject}</div>
                  </div>                 
                  {/* <div className="">{app.url}</div> */}
                </div>
                {/* <Fade bottom>
                  <p className="bridge-txt" style={{ padding: "30px 0" }}>
                    {app.description}
                  </p>
                </Fade> */}
              </Wrapper>
              </Bounce>
            </div>
          ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
width: 100%;
text-align: left;
`;
const HeaderInfo = styled.div`
  color:#000;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
