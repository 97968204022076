import { Col, Row, Card, Input, Form, Button } from 'antd';
import LoginService from '../../services/LoginService'
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

const Login = () => {
    const history = useHistory();
    const [invalid,setInvalid] =useState(false)
    const onFinish = (value) => {
        LoginService.login(value).then(res=>{
            if(res && res.token && res.userData){
                localStorage.setItem('TOKEN',res.token)
                localStorage.setItem('USER_DETAILS',JSON.stringify(res.userData))
                setInvalid(false)
                history.push('/listproject');
                window.location.reload()

            }
            else{               
            setInvalid(true) 
            }
        }).catch(e=>{
            console.log(e)
            setInvalid(true)
        })
    }
    return (
        <div>
            <Row style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                <Col span={12} className='login-back'></Col>
                <Col span={12} style={{display:'flex',justifyContent:'center'}}>
                    <Col xs={24} sm={20} md={17} lg={15} xl={12}>
                    <Card>
                        <div style={{fontSize:'20px',fontWeight:'700',textAlign:'center',color:'#136f5f'}}>Login</div>
                        <Form
                            name="basic"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <div style={{fontSize:'14px',fontWeight:'500'}}>User Name</div>
                            <Form.Item
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your username',
                                    },
                                ]}
                            >
                                <Input size="large" />
                            </Form.Item>
                            <div style={{fontSize:'14px',fontWeight:'500'}}>Password</div>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password',
                                    },
                                ]}
                            >
                                <Input.Password size="large" />
                            </Form.Item>
                            {invalid?(<div style={{textAlign:'center',color:'red',fontSize:'14px',fontWeight:'500',marginBottom:'10px'}}>Invalid Credentials !!</div>):<></>}
                            <Form.Item>
                                <Button style={{backgroundColor:'#f27937',color:'#fff'}} type="primary" block htmlType="submit">
                                    Login
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                    </Col>
                </Col>
            </Row>
        </div>
    );
};

export default Login;
