import React from 'react';
import styled from "styled-components";
import { Carousel } from 'antd';
import { Fade } from "react-reveal";
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Space } from 'antd';

const contentStyle = {
    margin: 0,
    // color: 'rgb(255, 255, 255)',
    // background: 'rgb(54, 77, 121)',
    lineHeight: "25px",
    display: 'flex',
    alignItems: 'center',
    padding: 40,
    flexDirection:'column',
    textAlign:'center'
    
};
const TestiMonials = () => {
  return (
    <div className="">
    <div className="container">       
      <div className="row flexCenter" style={{ paddingBottom: "100px" }}>
        <div className="col-xs-12 col-sm-12 col-md-12">
          <Fade left>
          <HeaderInfo>
            <h1 className="font40 extraBold" style={{ padding: "35px 0",}}>TESTIMONIALS</h1>
        </HeaderInfo>
        </Fade>
    <Carousel autoplay>
      <div>
        <div style={contentStyle}>
        <Avatar style={{marginBottom:15}} size={64} icon={<UserOutlined />} />
        <p style={{width:'40%',marginBottom:15}}>
        AGAF have a specialized testing team with DCRP approved Testing Engineers and high precision advanced testing equipments,  Electrical testing is needed for electrical equipment functioning properly after installation and has become a key component for equipment safety to include performance, operations, and energy requirements for electrical facilities.
        </p>
        </div>
      </div>
      <div>
      <div style={contentStyle}>
        <Avatar style={{marginBottom:15}} size={64} icon={<UserOutlined />} />
        <p style={{width:'40%',marginBottom:15}}>
        AGAF have a specialized testing team with DCRP approved Testing Engineers and high precision advanced testing equipments,  Electrical testing is needed for electrical equipment functioning properly after installation and has become a key component for equipment safety to include performance, operations, and energy requirements for electrical facilities.
        </p>
        </div>
      </div>
      <div>
      <div style={contentStyle}>
        <Avatar style={{marginBottom:15}} size={64} icon={<UserOutlined />} />
        <p style={{width:'40%',marginBottom:15}}>
        AGAF have a specialized testing team with DCRP approved Testing Engineers and high precision advanced testing equipments,  Electrical testing is needed for electrical equipment functioning properly after installation and has become a key component for equipment safety to include performance, operations, and energy requirements for electrical facilities.
        </p>
        </div>
      </div>
      <div>
      <div style={contentStyle}>
        <Avatar style={{marginBottom:15}} size={64} icon={<UserOutlined />} />
        <p style={{width:'40%',marginBottom:15}}>
        AGAF have a specialized testing team with DCRP approved Testing Engineers and high precision advanced testing equipments,  Electrical testing is needed for electrical equipment functioning properly after installation and has become a key component for equipment safety to include performance, operations, and energy requirements for electrical facilities.
        </p>
        </div>
      </div>
    </Carousel>
    </div>
    </div>
    </div>
    </div>
  );
  
};
const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
export default TestiMonials;

