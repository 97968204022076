import React from "react";
// Sections
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Governance from "../components/Sections/Governance";
import Joinus from "../components/Sections/Joinus";
// import Bridge from "../components/Sections/Bridge";
import Developer from "../components/Sections/Developer";
import Electrification from "../components/Sections/Electrification";
import TestingandCommissioning from "../components/Sections/TestingandCommissioning";
import TestiMonials from "../components/Sections/TestiMonials";
// export default function Landing() {
//   const path = window.location.href.split('0/')[1]

//  const state = useSelector(state =>state.user.value.name)
 
//  window.addEventListener('click',()=>{
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   path = path.length > 0 ? path : '';
// })
const LandingPage = (props) => {
//  useEffect(()=>{

//  },[state])

  return (
    <>  
      <Header/>      
      <Services />  
      <Governance />
      <Joinus />
      <Developer />
      <Electrification/>
      <TestingandCommissioning/>
      <TestiMonials />
      
      {/* <Footer /> */}
    </>
  )
};
export default LandingPage;
