import React from "react";
import styled from "styled-components";
import Fade from 'react-reveal/Fade';
import { Carousel,Row,Col,Button} from 'antd';
// Assets
import developerImg from "../../assets/img/banner4.jpg";
import developerImg1 from "../../assets/img/banner5.jpg";
import developerImg2 from "../../assets/img/banner1.jpg";

export default function Developer() {
  return (
    <Wrapper id="developer">
      <div className="lightBg">
        <div className="container">       
          <div className="row flexCenter" style={{ paddingBottom: "100px" }}>
            <div className="col-xs-12 col-sm-12 col-md-7">
            <Fade left>
              <HeaderInfo>
                <h1 className="font40 extraBold" style={{ padding: "35px 0" }}>UNDER GROUND <span className="highlight-txt"> CABLES.</span></h1>
                  <p className="font18 lineHeight40">
                  With the rapid advancements of electrical engineering, UG cables play a vital role in the transmission and distribution of electric power where it becomes impracticable to make use of overhead construction. AGAF explores ideas for the preparation and execution of cable works with all safety accessories and ensures safe work practices at the job site makes it more likely within the defined time and cost frame.  We have DCRP approved Cable Jointers with trained licenses from Raychem and REPL.  Our expertise team will guide you through your building commercial Load calculation and capacity of Cable required to install with approved voltage drop acceptance matching with OES standards    
                </p>
            </HeaderInfo>
            </Fade>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-5"> 
                <div style={{ marginTop: "50px"}}>
                <Carousel autoplay>
                <div>
                  
                <img src={developerImg} alt="logo" style={{width:'100%',minHeight:500}}/>
                </div>
                <div>
                <img src={developerImg1} alt="logo" style={{width:'100%',minHeight:500}}/>
                </div>
                <div>
                <img src={developerImg2} alt="logo" style={{width:'100%',minHeight:500}}/>
                </div>
              </Carousel>    
                </div>
             
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;









