import MainService from './MainService'
var LoginService={
    login(value){
        return new Promise(function (resolve, reject) {
            let $data={};
            $data.url='authenticate';
            $data.method='post';
            $data.data = value;
             MainService.getResult($data)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });
       
    }
};
export default LoginService;