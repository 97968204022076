import React from "react";
import styled from "styled-components";
import { Zoom } from "react-reveal";
// Components
// Assets
export default function Governance() {
  return (
    <Wrapper id="governance2">
      <div className="darkBg p-5 project-counts-wrapper">
        <div className="container">
        <Zoom left>
          <div className="row textCenter" >
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <div className="radius8">
                <div className="project-count" style={{ margin: "0px 0"}}>
                  <div className="project-value extraBold mb-3">500+</div>
                  <div className="font20 project-label">constructed till date</div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <div className="radius8">
                <div className="project-count" style={{ margin: "0px 0"}}>
                  <div className="project-value extraBold mb-3">25k+</div>
                  <div className="font20 project-label">Meters of OHL installed</div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <div className="radius8">
                <div className="project-count" style={{ margin: "0px 0"}}>
                  <div className="project-value extraBold mb-3">35k+</div>
                  <div className="font20 project-label">Meters of UG Cable</div>
                </div>
              </div>
            </div>
          </div>
          </Zoom>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

