import axios from "axios";
import config from './config.json'
var MainService={
    getResult(data){
        data.url = config.apiUrl + data.url;
        if(data.method=='get'){
           return axios.get(data.url, {params: data.params});
        }else if(data.method=='post'){
            return axios.post(data.url, data.data)
        }
    }
};
export default MainService;