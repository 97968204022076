import React, { useEffect, useState } from "react";
import Logo from "./assets/img/logo.jpg";
import { BrowserRouter as Router, Route, NavLink, Link, Switch } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import styled from "styled-components";
import { Col, Row, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { PhoneOutlined,MailOutlined } from '@ant-design/icons';
export default function TopMenu() {
    const history = useHistory();
    const [y, setY] = useState(window.scrollY);
    const [expanded, setExpanded] = useState(false);
    useEffect(() => {
    
        window.addEventListener("scroll", () => setY(window.scrollY));
        return () => {
          window.removeEventListener("scroll", () => setY(window.scrollY));
        };
      }, [y]);
    document.addEventListener("DOMContentLoaded", function () {
        window.addEventListener('scroll', function () {
          if (window.scrollY > 141) {
            document.getElementById('navbar_top').classList.add('fixed-top');
          } else {
            document.getElementById('navbar_top').classList.remove('fixed-top');
          }
    
        });
      });
    function getLogout(){
        history.push('/login')
        window.location.reload()
    }
  const handleSelect = () => {
    setExpanded(!expanded);
    // Add any other logic you want to perform on menu item selection
  };
    return (
      <>
      <div  id="navbar_top" style={y > 141 ? { height: "141px" } : { height: "141px" }}>
      <Row className="top-menu">
        <div className="container" style={{width:'100%',display:'flex'}}>      
      <Col span={12}>
     <PhoneOutlined className="phoneIcon" style={{marginRight:3}}/> : <span style={{marginLeft:3}} >+123 123 456 7890</span>
        </Col>
        <Col span={12}  align={'end'}>
          <span className="mr-4"><MailOutlined style={{marginRight:3}}/> : <span style={{marginLeft:3}} > info@agaf.com</span></span> 
          {/* <Link  style={{color:'#fff'}} onClick={()=>getLogout()}><UserOutlined className="mr-1" /> Login</Link> */}
        </Col>
        </div>
    </Row>
    <Navbar expanded={expanded} expand="lg" className="navbar-light">
      <Container>
        <Navbar.Brand href="/">
          <img src={Logo} className="img-fluid w-75" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)}/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto order-0" onSelect={handleSelect}>
            <Nav.Link as={NavLink} to="/" exact activeClassName="active">Home</Nav.Link>
            <Nav.Link as={NavLink} to="/aboutus" exact activeClassName="active">About Us</Nav.Link>
            <Nav.Link as={NavLink} activeClassName="active" to="/projects">Our Client & Projects</Nav.Link>
            <Nav.Link as={NavLink} to="/certifications" exact activeClassName="active">QHSE & Certification</Nav.Link>
            <Nav.Link as={NavLink} to="/contactus" exact activeClassName="active">Contact Us</Nav.Link>
          </Nav>
          {/* <Nav className="ml-auto">
            <Nav.Link href="javascript:;" onClick={()=>getLogout()} >
              <button class="lp-btn btn sm">Login</button>
            </Nav.Link>
          </Nav> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
        </>
    )
}
