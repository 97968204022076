import React from "react";
import styled from "styled-components";
import rhsimg from "../../assets/img/2.png";
import Fade from 'react-reveal/Fade';
import Slider from "react-slick";
import ws1 from '../../assets/img/banner3.jpg';
import ws2 from '../../assets/img/banner5.jpg';
import ws3 from '../../assets/img/banner2.jpg';
export default function Certifications() {
  const settings = {
    slidesToShow: 3,
    speed: 500,
    arrows: true,
    dots: true,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 1,
        centerPadding: "0%",
        arrows: true,
        dots: false,
      },
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        centerPadding: "0%",
        arrows: true,
        dots: false,
      }
    }]
  };

  var sliderImage = [
    { sliderimg: ws1 },
    { sliderimg: ws2 },
    { sliderimg: ws3 },
    { sliderimg: ws1 },
    { sliderimg: ws2 },
    { sliderimg: ws3 }
  ]
  return (
    <Wrapper id="aboutus" className="whiteBg">
      <div className="banner-sec-bg">
        <div className="container" style={{ margin: "0px auto 0 auto" }}>
          <div className="row align-items-center">
            <div className="col-md-6">
              <HeaderInfo >
                <h1 className="font40 extraBold whiteColor">QHSE & TRAINING</h1>
              </HeaderInfo>
            </div>
            {/* <div className="col-md-6 textRight">
                <img src={bannerImg} className="img-fluid allapps-banner"/>
              </div> */}
          </div>
        </div>
      </div>
      <div style={{ padding: "60px 0" }}>
        <div className="lightBg py-5 my-5">
        <div className="container">
          <div className="row align-items-center">
                <div className="col-lg-10">
                  <Fade top>
                    <HeaderInfo>
                      <h1 className="font30 extraBold text-uppercase" style={{ padding: "35px 0" }}>AGAF HSE Statement</h1> 
                      <h4 className="mb-3">Our inevitable policy <span className="extraBold">“ SAFETY FIRST, DUTY MUST”.</span> </h4>
                      <p className="font18 lineHeight30 mb-4">
                      AGAF has a personal responsibility, to take maximum care for the health and safety of themselves and any other person who may be affected by their activities at work. AGAF accepts its responsibilities as a contractor for providing a safe and healthy workplace for its employees, clients, visitors, and public. Our management is focusing on their responsibilities by initiating the QHSE programs, training, and safety meetings for our employees regularly to meet our aims and business objectives.  Our HSE policy aims at establishing AGAF LLC as one of the leaders in occupational health, safety, and protection of the environment. We believe that a positive HSE record is an integral part of the quality of services offered to the client.
                      </p>
                    </HeaderInfo>
                  </Fade>
                </div>
              </div>
              </div>
        </div>
        <div className="py-5 my-5">
        <div className="container">
          <div className="row">
                <div className="col-lg-6">
                  <Fade left>
                    <HeaderInfo>
                      <h1 className="font30 extraBold text-uppercase mb-3">IMPORTANCE ON QUALITY & HSE TRAINING TO STAFF AND WORKERS.</h1> 
                      <p className="font18 lineHeight30 mb-4">
                      AGAF strongly believes that training leads to better processes and Better Work, Training is a critical piece of the S.A.F.E. program and it begins with hiring the most qualified technicians, Our employees are our greatest assets. We are committed to providing our team members with the training and resources they need to succeed professionally and personally. Our training programs equip them with the skills and knowledge necessary to deliver exceptional workmanship and service to our clients. We provide ongoing safety training and education to ensure team members work safely and effectively.  This training is supplemented with AGAF own in-house training programs which cover a variety of safety topics including general safety, equipment-specific training, and duty-specific training, working on Difficult Terrains of Wadi, Mountains, Desert.  
                      </p>
                    </HeaderInfo>
                  </Fade>
                </div>
                <div className="col-lg-6">
                  <Fade right>
                    <HeaderInfo>
                      <h1 className="font30 extraBold text-uppercase mb-3">Safety Orientation</h1> 
                      <h4 className="mb-3">In-house training and out-sourced training on additional topics </h4>
                      <p className="font18 lineHeight30 mb-4">
                      First Aid, CPR, Fire Safety, PPE, Hazcom, Emergency Procedures, Equipment training, Hi-lift training, Working at Heights, Personal Hygeine, Material Storage, Chemical Handling. Annual Training and Re-certifications Weekly Toolbox Talks   
                      </p>
                    </HeaderInfo>
                  </Fade>
                </div>
              </div>
              </div>
        </div>
        
        <div className="lightBg py-5 my-5">
          <div className="container">
            <h1 className="font30 extraBold text-uppercase" style={{ padding: "35px 0" }}>TRAINING PHOTOS</h1>
            <div className='row banner-subpage slider-section'>
              <div class="slider">
                <div class="slide">
                  <Slider {...settings}>

                    {sliderImage.map(item => (
                      <div>
                        <div className="slider-card">
                          <img src={item.sliderimg} className="img-fluid" style={{height:300}}/>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;