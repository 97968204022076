import MainService from './MainService'
var ProjectService={
    create(value){
        return new Promise(function (resolve, reject) {
            let $data={};
            $data.url='createProject';
            $data.method='post';
            $data.data = value;
             MainService.getResult($data)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error);
                })
        });
       
    },
    findAll(value){
        return new Promise(function (resolve, reject) {
            let $data={};
            $data.url='getProject';
            $data.method='get';
            $data.params = value;
             MainService.getResult($data)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });
       
    },
    findAllWithUrl(value){
        return new Promise(function (resolve, reject) {
            let $data={};
            $data.url='getProjectWithUrl';
            $data.method='get';
            $data.params = value;
             MainService.getResult($data)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });
       
    },
    findOne(value){
        return new Promise(function (resolve, reject) {
            let $data={};
            $data.url='getOneProject/'+value.id
            $data.method='get';
             MainService.getResult($data)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });
       
    }
};
export default ProjectService;