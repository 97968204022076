import React from "react";
import styled from "styled-components";
import rhsimg from "../../assets/img/2.png";
import Fade from 'react-reveal/Fade';
import Slider from "react-slick";
import ws1 from '../../assets/img/1.png';
import ws2 from '../../assets/img/2.png';
import ws3 from '../../assets/img/3.png';
export default function Contactus() {
  const settings = {
    slidesToShow: 3,
    speed: 500,
    arrows: true,
    dots: true,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 1,
        centerPadding: "0%",
        arrows: true,
        dots: false,
      },
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        centerPadding: "0%",
        arrows: true,
        dots: false,
      }
    }]
  };

  var sliderImage = [
    { sliderimg: ws1 },
    { sliderimg: ws2 },
    { sliderimg: ws3 },
    { sliderimg: ws1 },
    { sliderimg: ws2 },
    { sliderimg: ws3 }
  ]
  return (
    <Wrapper id="aboutus" className="whiteBg">
      <div className="banner-sec-bg">
        <div className="container" style={{ margin: "0px auto 0 auto" }}>
          <div className="row align-items-center">
            <div className="col-md-6">
              <HeaderInfo >
                <h1 className="font40 extraBold whiteColor">Contact Us</h1>
              </HeaderInfo>
            </div>
            {/* <div className="col-md-6 textRight">
                <img src={bannerImg} className="img-fluid allapps-banner"/>
              </div> */}
          </div>
        </div>
      </div>
      <div style={{ padding: "60px 0" }}>
        <section class="Material-contact-section section-padding section-dark">
          <div class="container">

            <div class="row">
              <div class="col-md-6 mt-3 contact-widget-section2 wow animated fadeInLeft" data-wow-delay=".2s">
                <h4 className="mb-4 extraBold">CORPORATE ADDRESS</h4>
                <div class="find-widget">
                  <a href="#" className="extraBold mb-2 d-block">AL GULNAR AL FAWAH TRAD. AND CONT. LLC</a>
                </div>
                <div class="find-widget">
                  <a href="#">Postal Code: 320 , Post Box: 765,	<br />
                    Building No.490, Flat No.4,	<br />
                    Al Juhaila, Barka<br />
                    Sultanate of OMAN.<br /></a>
                </div>
                <div class="find-widget">
                  Contact No:  <a href="#">91391971</a>
                </div>
                <div class="find-widget">
                  E-MAIL:  <a href="mailto:sales@agafoman.com">sales@agafoman.com</a>
                </div>
                <div className="mt-4">
                    <h4 className="mb-4 extraBold">SITE ADDRESS</h4>
                    <div class="find-widget">
                      <a href="#" className="extraBold mb-2 d-block">AL GULNAR AL FAWAH TRAD. AND CONT. LLC</a>
                    </div>
                    <div class="find-widget">
                      <a href="#">Postal Code: 320 , Post Box: 765,	<br />
                        Building No.490, Flat No.4,	<br />
                        Al Juhaila, Barka<br />
                        Sultanate of OMAN.<br /></a>
                    </div>
                    <div class="find-widget">
                      Contact No:  <a href="#">91391971</a>
                    </div>
                    <div class="find-widget">
                      E-MAIL:  <a href="mailto:sales@agafoman.com">sales@agafoman.com</a>
                    </div>
                </div>
              </div>
              <div class="col-md-6">
                <form class="shake" role="form" method="post" id="contactForm" name="contact-form" data-toggle="validator">
                  <div className="row">
                    <div class="col-md-6">
                      <div class="form-group label-floating mb-3">
                        <label class="control-label" for="name">Name</label>
                        <input class="form-control" id="name" type="text" name="name" required data-error="Please enter your name" />
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group label-floating mb-3">
                        <label class="control-label" for="email">Contact No</label>
                        <input class="form-control lg" id="email" type="email" name="email" required data-error="Please enter your Email" />
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group label-floating mb-3">
                        <label class="control-label" for="email">Email</label>
                        <input class="form-control lg" id="email" type="email" name="email" required data-error="Please enter your Email" />
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group label-floating mb-3">
                        <label class="control-label">Working at</label>
                        <input class="form-control" id="msg_subject" type="text" name="subject" required data-error="Please enter your message subject" />
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group label-floating mb-3">
                        <label class="control-label">Official Email </label>
                        <input class="form-control" id="msg_subject" type="text" name="subject" required data-error="Please enter your message subject" />
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group label-floating mb-3">
                        <label class="control-label">Official Address</label>
                        <textarea class="form-control" rows="3" id="message" name="message" required data-error="Write your message"></textarea>
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>
                  </div>
                  <div class="form-submit mt-2">
                    <button class="lp-btn btn sm">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;