import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Space, Table, Row, Col, Tag } from 'antd';
import Button from 'react-bootstrap/Button';
import ProjectService from '../../services/ProjectService';
import { useLocation } from 'react-router-dom';


const ProjectList = () => {
  
  const history = useHistory();
  const location = useLocation();
  var stage = new URLSearchParams(location.search).get('stage');
  const columns = [

    // {
    //   title: 'Id',
    //   dataIndex: 'id',
    //   key: 'id',
    //   render: (text) => <a>{text}</a>,
    // },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Stage',
      key: 'stage',
      dataIndex: 'stage',
      render: (stage) => (
        <Tag color={stage === 'Open' ? 'green' : 'blue'} key={stage}>
          {stage}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => getAction(record.id, 'edit')}>Edit {record.name}</a>
          <a onClick={() => getAction(record.id, 'view')}>View</a>
        </Space>
      ),
    },
  ];

  function getAction(id, type) {
    navigateToProjectView(id, type);
  }

  const [projectData, setProjectData] = useState([]);

  useEffect(() => {
    getProjectData();
  }, [stage]);

  const getProjectData = () => {
    let obj ={}
    if(stage){
      obj.stage=stage.toLowerCase()=='open'?'Open':'Completed'
    }
    ProjectService.findAll(obj)
      .then((res) => {
        setProjectData(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const openProject = (data) => {
    navigateToProjectView(data.id);
  };

  const navigateToProjectView = (projectId, type) => {
    history.push(`/${type == 'edit' ? 'addprojects' : 'viewproject'}?id=${projectId}&type=${type}`);
  };

  return (

    <Row style={{ padding: '80px' }}>
      <Col span={24}>
        <Row >
          <Col span={16}>
            <div>
              <h1>Project</h1>
            </div>
          </Col>
          <Col span={8}>
            <div justify="end">
              <Button className='lp-btn btn' onClick={() => history.push('/addprojects')} type="reset">
                Add Project
              </Button>
            </div>
          </Col>
        </Row>

      </Col>
      <Col span={20} style={{ paddingTop: '40px' }}>
        <Table columns={columns} dataSource={projectData} />
      </Col>
    </Row>


  );
};

export default ProjectList;
