import React from "react";
import styled from "styled-components";
// Assets
import joinusImg from "../../assets/img/3.png";
import Fade from 'react-reveal/Fade';
import rhsimg from "../../assets/img/banner3.jpg";
import secondImage from '../../assets/img/banner2.jpg'
import thirdImage from '../../assets/img/banner5.jpg'
import { Carousel} from 'antd';
// Assets


export default function Joinus() {
  return (
    <Wrapper id="governance" className="pb-5 mb-5">
      <div className="container">
          <div className="row flexCenter" style={{ paddingBottom: "30px" }}>            
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5">  
          <div style={{ marginTop: "50px"}} className="joinus-wrapper">  
          <Carousel autoplay>
                <div>
                  
                <img src={rhsimg} alt="logo" style={{width:'100%',minHeight:500}}/>
                </div>
                <div>
                <img src={secondImage} alt="logo" style={{width:'100%',minHeight:500}}/>
                </div>
                <div>
                <img src={thirdImage} alt="logo" style={{width:'100%',minHeight:500}}/>
                </div>
              </Carousel>    
              </div>   
           {/* <div style={{ width: "100%"}}>
             <div style={{ marginTop: "50px"}} className="joinus-wrapper">
               <img src={joinusImg} alt="office" className="img-fluid"/>
             </div>
           </div>           */}
         </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7">
            <Fade right>
              <HeaderInfo>
                <h1 className="font40 extraBold" style={{ padding: "35px 0" }}>OVER <span className="highlight-txt">HEAD LINES.</span></h1>
                  <p className="font18 lineHeight40">
                  Since our inception in 2007, we started construction and maintenance services for Medium voltage (Upto 33 KV) overhead Distribution systems.  we have constructed and maintained several hundreds of kilometre in distribution systems covering major parts of oman.  The electricity Autority companies of batinah regions specially greeted AGAF for our contribution in toughest terrains of Rustaq & Wadi regions.  Our Expertise team have vast experience working in all type of terrain with different pole structure installation includes Wooden Poles, Concrete Poles & Lattice Steel Tower - Taking responsibility is the key that we planned so that it can be done safely and equipment is safe. following the Oman Electrical Standards  and Having created the benchmark in the community we have been set an excellent track record with regard to customer satisfaction 
                </p>
            </HeaderInfo>
            </Fade>
            </div>
          </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;