import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { Col, Row, Button, Divider, Avatar } from 'antd';
import {
  UserOutlined,
  MailOutlined,
  InstagramOutlined,
  GoogleOutlined,
  UpOutlined
} from '@ant-design/icons';
// Assets


export default function Footer() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="darkBg whiteColor footerLight">
        <div className="container">
          <Row gutter={[16, 16]} className="footer-top">
            <Col xs={12} sm={12} md={8} lg={8} xl={8} align={'center'}>
              <Avatar className="footer-address" size={40} icon={<MailOutlined />} />
              <p className="addressList">(+91)9876543201</p>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={8} align={'center'}>
              <Avatar className="footer-address" size={40} icon={<MailOutlined />} />
              <p className="addressList">hello@Messagegmail.com</p>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={8} align={'center'}>
              <Avatar className="footer-address" size={40} icon={<MailOutlined />} />
              <p className="addressList">13/12 Anna Street Channai</p>
            </Col>
          </Row>
          <Divider style={{ backgroundColor: '#6d6d6d' }} />
          <Row style={{paddingTop:10}}>
              <Col xs={12} sm={12} md={8} lg={6} xl={6} align={'center'}>
                <div className="font14 extraBold" style={{ padding: "0px 0 10px",color:'#aaaaaa' }}>About AGAF</div>
                <ul className="lineHeight30">
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>Contact Us</div></li>
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>Help & About us</div></li>
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>Contact</div></li>
                </ul>
              </Col>
          
              <Col xs={12} sm={12} md={8} lg={6} xl={6} align={'center'} >
                <div className="font14 extraBold" style={{ padding: "0px 0 10px",color:'#aaaaaa' }}>Our Projects</div>
                <ul className="lineHeight30">
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>On Going </div></li>
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>Completed</div></li>
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>Yet to Start</div></li>
                </ul>
              </Col>

              <Col xs={12} sm={12} md={8} lg={6} xl={6} align={'center'}>
                <div className="font14 extraBold" style={{ padding: "0px 0 10px",color:'#aaaaaa' }}>Menus</div>
                <ul className="lineHeight30">
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>Home</div></li>
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>About Us</div></li>
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>Clients</div></li>
                </ul>
              </Col>
      
              <Col xs={12} sm={12} md={8} lg={6} xl={6} align={'center'} >
                <div className="font14 extraBold" style={{ padding: "0px 0 10px",color:'#aaaaaa' }}>Construction</div>
                <ul className="lineHeight30">
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>Documentation</div></li>
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>Block explorer</div></li>
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>Status</div></li>
                </ul>
              </Col>
          </Row>    
          {/* <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>

            <StyleP className="whiteColor font15">
              © {getCurrentYear()} - <span className="purpleColor font15">AGAF </span> All Right Reserved
            </StyleP>

            <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
              Back to top
            </Link>
          </InnerWrapper> */}
        </div>
        <Row gutter={[16, 16]} style={{backgroundColor:'rgb(5 16 41)',padding:20,marginLeft:0,marginRight:0}}>
            <Col  xs={24} sm={12} md={7} lg={8} xl={8} align={'center'} >
            <StyleP className="whiteColor font15" style={{paddingTop:7}}>
              © {getCurrentYear()} - <span className="purpleColor font15">AGAF </span> All Right Reserved
            </StyleP>
            </Col>
            <Col  xs={24} sm={12} md={10} lg={8} xl={8} align={'center'} >
            <Avatar style={{border:'1',borderColor:'#838383',marginLeft:25}} icon={<InstagramOutlined />} />
            <Avatar style={{border:'1',borderColor:'#838383',marginLeft:25}} icon={<GoogleOutlined />} />
            <Avatar style={{border:'1',borderColor:'#838383',marginLeft:25}} icon={<InstagramOutlined />} />
            <Avatar style={{border:'1',borderColor:'#838383',marginLeft:25}} icon={<GoogleOutlined />} />
            </Col>
            <Col  xs={24} sm={12} md={7} lg={8} xl={8} align={'center'} >
            <Avatar style={{border:'1',borderColor:'#838383'}} icon={<UpOutlined />} />
            </Col>
          </Row>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;