import React from "react";
import styled from "styled-components";
// Assets
import joinusImg from "../../assets/img/banner5.jpg";
import Fade from 'react-reveal/Fade';
// Assets


export default function Electrification() {
  return (
    <Wrapper id="governance" className="pb-5 mb-5">
      <div className="container">
          <div className="row flexCenter" style={{ paddingBottom: "30px" }}>            
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5">           
           <div style={{ width: "100%"}}>
             <div style={{ marginTop: "50px"}} className="joinus-wrapper">
               <img src={joinusImg} alt="office" className="img-fluid"/>
             </div>
           </div>          
         </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7">
            <Fade right>
              <HeaderInfo>
                <h1 className="font40 extraBold" style={{ padding: "35px 0" }}>INDUSTRIAL <span className="highlight-txt">ELECTRIFICATION.</span></h1>
                  <p className="font18 lineHeight40">
                  AGAF scrutinizes every promising opportunity from industries for financial and environmental benefits to planning for the new ideas that can be implemented through electric technologies for their use. We assess the technological potential of industrial electrification day by day and achieve exponential growth towards the next set of stages.  We have completed various industrial projects in specialized zones like Sohar Free zone, Industrial Estate (Madayn), Khazaein Economic City - Special Economic Zone at Duqm. 
                </p>
            </HeaderInfo>
            </Fade>
            </div>
          </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;