import React from "react";
import styled from "styled-components";
// Components
import Particle from "../particles/Particles";
import bannerrhs from "../../assets/img/3.png";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import { Carousel,Row,Col,Button} from 'antd';
import firstImage from '../../assets/img/banner1.jpg'
import secondImage from '../../assets/img/banner2.jpg'
import thirdImage from '../../assets/img/banner3.jpg'
export default function Header() {
  const images = [
    { src: firstImage, title: "AL GULNAR AL FAWAH TRAD. AND CONT. LLC", description: " Al Gulnar Al Fawah Trading and Contracting LLC. (AGAF) is a DCRP & JSRS certified electrical contracting company headquartered in Barka, Sultanate of Oman. AGAF started its pioneer set up in the year 2007 & has grown up steadily to become one of the top-class engineering organizations offering quality services in Electrical Distribution and EPC Projects." },
    { src: secondImage, title: "AL GULNAR AL FAWAH TRAD. AND CONT. LLC", description: " Al Gulnar Al Fawah Trading and Contracting LLC. (AGAF) is a DCRP & JSRS certified electrical contracting company headquartered in Barka, Sultanate of Oman. AGAF started its pioneer set up in the year 2007 & has grown up steadily to become one of the top-class engineering organizations offering quality services in Electrical Distribution and EPC Projects." },
    { src: thirdImage, title: "AL GULNAR AL FAWAH TRAD. AND CONT. LLC", description: " Al Gulnar Al Fawah Trading and Contracting LLC. (AGAF) is a DCRP & JSRS certified electrical contracting company headquartered in Barka, Sultanate of Oman. AGAF started its pioneer set up in the year 2007 & has grown up steadily to become one of the top-class engineering organizations offering quality services in Electrical Distribution and EPC Projects." },
  ];
  return (
    <>
    <div className="position-relative mobile-res">   
   
    <Carousel autoplay>
    {images.map((image, index) => (
        <div key={index}>
          <Row>
            <Col span={24}>
              <div style={{ position: "relative" }}>
                <div className="d-none d-md-block">
                  <Particle/>  
                </div>             
                <img src={image.src} alt="logo" style={{width:'100%'}}/>
                <div className="banner-txt-wrap">
                  <div className="title-txt title-lg mt-3 animate-charcter">
                    {image.title}
                  </div>
                  <br></br>
                  <Zoom>
                    <HeaderP className="whiteColor mt-md-4">
                      {image.description}
                    </HeaderP>   
                  </Zoom>  
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ))}
  </Carousel>

  </div>
    </>
  );
}

const HeaderH1 = styled.div `
  padding:0 0px 0;
  font-size:40px;
  line-height:50px;
`

const Wrapper = styled.section`
  width: 100%;
  min-height:767px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const HeaderP = styled.div`
  padding:0 40px 40px 0px;
  font-size:22px;
  line-height:38px;
  @media (max-width: 960px) {
    padding:0px 15px 50px 0px;
    max-width: 100%;
    font-size:11px;
    line-height:18px;
  }
`;
