import React, { useEffect, useState } from "react";
import LandingPage from "./screens/Landing.jsx";
import Aboutus from "./components/Pages/Aboutus.jsx";
import Projects from "./components/Pages/Projects.jsx"
import AddProject from "./components/Sections/AddProject.jsx";
import ListProject from "./components/Sections/ListProject.jsx";
import ViewProject from "./components/Sections/ViewProject.jsx";
import Contactus from "./components/Pages/Contactus.jsx";
import Certifications from "./components/Pages/Certifications.jsx";
import { BrowserRouter as Router, Route, NavLink, Switch } from 'react-router-dom';
import Login from "./components/Login/Login.jsx";
import Table from './Table.js'
export default function AppRoute() {
    return(
        <>
      <Switch>
        <Route exact path="/aboutus" component={Aboutus} />
        <Route exact path="/projects" component={Projects} />
        <Route exact path="/contactus" component={Contactus} />
        <Route exact path="/certifications" component={Certifications} />
        
        <Route exact path="/login" component={Login} />
        <Route exact path="/addprojects" component={AddProject} />
        <Route exact path="/listproject" component={ListProject} />
        <Route exact path="/viewproject" component={ViewProject} />
        <Route exact path="/table" component={Table} />
        <Route exact  path="/" render={(props) => (
              <LandingPage />
            )}
          />
      </Switch>
      </>
    )
}